import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IJob1 {
RecId:number,
EntryDate?:Date,
Status?:string,
CustomerName?:string,
LeadDate?:Date,
CancelledDate?:Date,
EnqDate?:Date,
POL?:string,
POD?:string,
Cntr20?:string,
Cntr40_40Hc?:string,
AIR_LCR?:string,
PeriodOfShpmt?:string,
Commodity?:string,
RateReqDate?:Date,
RateRcvdDate?:Date,
BuyRate20?:number,
BuyRate40?:number,
SellRate20?:number,
SellRate40?:number,
Profit?:number,
QtnSentDate?:Date,
CnfmRcvdDate?:Date,
jobdate?:Date,
JobNo?:string,
BkgSentToLineorAgtDate?:Date,
CroReceivedDate?:Date,
CroSentToTransDate?:Date,
CroNo?:string,
ContrNo?:string,
ContrNoRecDate?:Date,
WBillNo?:string,
WBillSentDate?:Date,
SealNo?:string,
SealNoRecDate?:Date,
FullMovedToTerminal?:string,
ShippingInstRecDate?:Date,
ShipInstSentDateToSl?:Date,
ManifestRecDateFromSl?:Date,
ShiperClDocDate?:Date,
BlDraftFromSlRecDate?:Date,
BlDraftToCustSentDate?:Date,
BlDraftConfFromCustRecDate?:Date,
DocSentToBrokerDate?:Date,
ManifestSentToBrokerDate?:Date,
GatePassRecDate?:Date,
GatePassSentToTransDate?:Date,
FinalBayanorOkToLoadRecDate?:Date,
LoadingConfirmationRecDate?:Date,
PreAlertSentDate?:Date,
ArrivalNotificationSentDate?:Date,
InvoicePreparedDate?:Date,
HBLDate?:Date,
MBLDate?:Date,
DebitorCreditNoteDate?:Date,
ETADate?:Date,
TelexReleaseorSeawayBillDate?:Date,
OblorGuaranteeCollectedDate?:Date,
DocumentsReleasedDate?:Date,
PaymentRecDate?:Date,
Remarks?:string
}

interface IJob1Data {
    list?: Array<IJob1>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IJob1Data = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const job1Slice = createSlice({
    name: "job1",
    initialState,
    reducers: {
        setJob1List: (state, _action: PayloadAction<IJob1Data>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetJob1ToInit: (state) => {
            state = initialState;
        },
        setJob1Message: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setJob1List, resetJob1ToInit, setJob1Message } = job1Slice.actions;

export default job1Slice.reducer;

