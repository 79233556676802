import { Field, useFormik } from "formik";
import React from "react";
import DatePicker from "react-datepicker";
import { Col, Row } from "react-bootstrap";

import { Button, Card, Form } from "react-bootstrap";
import { setJob1Message } from "redux/actions";

import { useAppDispatch } from "redux/store";
import { addJob1, updateJob1 } from "services/job1Service";
import { Constant } from "template/Constant";
import * as yup from "yup";
import moment from "moment";

type Props = {
  row?: any;
  hideShowForm: (actionName) => void;
  getData: (page, pageSize, searchKey) => void;
  action?: string;
};
export const Job1Form: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();
  const iValue = {
    RecId: 0,
    EntryDate: null,
    Status: "",
    CustomerName: "",
    LeadDate: null,
    CancelledDate: null,
    EnqDate: null,
    POL: "",
    POD: "",
    Cntr20: "",
    Cntr40_40Hc: "",
    AIR_LCR: "",
    PeriodOfShpmt: "",
    Commodity: "",
    RateReqDate: null,
    RateRcvdDate: null,
    BuyRate20: 0,
    BuyRate40: 0,
    SellRate20: 0,
    SellRate40: 0,
    Profit: 0,
    QtnSentDate: null,
    CnfmRcvdDate: null,
    jobDate: null,
    JobNo: "",
    BkgSentToLineorAgtDate: null,
    CroReceivedDate: null,
    CroSentToTransDate: null,
    CroNo: "",
    ContrNo: "",
    ContrNoRecDate: null,
    WBillNo: "",
    WBillSentDate: null,
    SealNo: "",
    SealNoRecDate: null,
    FullMovedToTerminal: "",
    ShippingInstRecDate: null,
    ShipInstSentDateToSl: null,
    ManifestRecDateFromSl: null,
    ShiperClDocDate: null,
    BlDraftFromSlRecDate: null,
    BlDraftToCustSentDate: null,
    BlDraftConfFromCustRecDate: null,
    DocSentToBrokerDate: null,
    ManifestSentToBrokerDate: null,
    GatePassRecDate: null,
    GatePassSentToTransDate: null,
    FinalBayanorOkToLoadRecDate: null,
    LoadingConfirmationRecDate: null,
    PreAlertSentDate: null,
    ArrivalNotificationSentDate: null,
    InvoicePreparedDate: null,
    HBLDate: null,
    MBLDate: null,
    DebitorCreditNoteDate: null,
    ETADate: null,
    TelexReleaseorSeawayBillDate: null,
    OblorGuaranteeCollectedDate: null,
    DocumentsReleasedDate: null,
    PaymentRecDate: null,
    Remarks: "",
  };
  const initialValue = action === "edit" ? row : iValue;

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      if (action === "edit") {
        const response = await updateJob1(row.RecId, values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(setJob1Message("Updated Successfully"));
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(setJob1Message("Some error occured!"));
        }
      } else if (action === "add") {
        const response = await addJob1(values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(setJob1Message("Added Successfully"));
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(setJob1Message("Some error occured!"));
        }
      }
    },
    validationSchema: yup.object({
      RecId: yup.number().required("RecId is required"),
      EntryDate: yup.date().nullable(),
      Status: yup.string().nullable(),
      CustomerName: yup.string().nullable(),
      LeadDate: yup.date().nullable(),
      CancelledDate: yup.date().nullable(),
      EnqDate: yup.date().nullable(),
      POL: yup.string().nullable(),
      POD: yup.string().nullable(),
      Cntr20: yup.string().nullable(),
      Cntr40_40Hc: yup.string().nullable(),
      AIR_LCR: yup.string().nullable(),
      PeriodOfShpmt: yup.string().nullable(),
      Commodity: yup.string().nullable(),
      RateReqDate: yup.date().nullable(),
      RateRcvdDate: yup.date().nullable(),
      BuyRate20: yup.number().nullable(),
      BuyRate40: yup.number().nullable(),
      SellRate20: yup.number().nullable(),
      SellRate40: yup.number().nullable(),
      Profit: yup.number().nullable(),
      QtnSentDate: yup.date().nullable(),
      CnfmRcvdDate: yup.date().nullable(),
      jobdate: yup.date().nullable(),
      JobNo: yup.string().nullable(),
      BkgSentToLineorAgtDate: yup.date().nullable(),
      CroReceivedDate: yup.date().nullable(),
      CroSentToTransDate: yup.date().nullable(),
      CroNo: yup.string().nullable(),
      ContrNo: yup.string().nullable(),
      ContrNoRecDate: yup.date().nullable(),
      WBillNo: yup.string().nullable(),
      WBillSentDate: yup.date().nullable(),
      SealNo: yup.string().nullable(),
      SealNoRecDate: yup.date().nullable(),
      FullMovedToTerminal: yup.string().nullable(),
      ShippingInstRecDate: yup.date().nullable(),
      ShipInstSentDateToSl: yup.date().nullable(),
      ManifestRecDateFromSl: yup.date().nullable(),
      ShiperClDocDate: yup.date().nullable(),
      BlDraftFromSlRecDate: yup.date().nullable(),
      BlDraftToCustSentDate: yup.date().nullable(),
      BlDraftConfFromCustRecDate: yup.date().nullable(),
      DocSentToBrokerDate: yup.date().nullable(),
      ManifestSentToBrokerDate: yup.date().nullable(),
      GatePassRecDate: yup.date().nullable(),
      GatePassSentToTransDate: yup.date().nullable(),
      FinalBayanorOkToLoadRecDate: yup.date().nullable(),
      LoadingConfirmationRecDate: yup.date().nullable(),
      PreAlertSentDate: yup.date().nullable(),
      ArrivalNotificationSentDate: yup.date().nullable(),
      InvoicePreparedDate: yup.date().nullable(),
      HBLDate: yup.date().nullable(),
      MBLDate: yup.date().nullable(),
      DebitorCreditNoteDate: yup.date().nullable(),
      ETADate: yup.date().nullable(),
      TelexReleaseorSeawayBillDate: yup.date().nullable(),
      OblorGuaranteeCollectedDate: yup.date().nullable(),
      DocumentsReleasedDate: yup.date().nullable(),
      PaymentRecDate: yup.date().nullable(),
      Remarks: yup.string().nullable(),
    }),
  });
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {action} Job1
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm(false)}
          >
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">View Job1</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <div className="row">
            <Form.Group className="col-md-4">
              <label className="form -control-label">RecId</label>
              <Form.Control
                type="number"
                name="RecId"
                className="form-control "
                value={formik.values.RecId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.RecId && !!formik.errors.RecId}
                isValid={!!formik.touched.RecId && !formik.errors.RecId}
                disabled={true}
              ></Form.Control>
              {formik.errors.RecId && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.RecId}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">EntryDate</label>
              <Form.Control
                type="date"
                name="EntryDate"
                className="form-control"
                // value={moment(formik.values.EntryDate, "dd-MM-YYYY").toString()}
                value={formik.values.EntryDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.EntryDate && !!formik.errors.EntryDate
                }
                isValid={!!formik.touched.EntryDate && !formik.errors.EntryDate}
              ></Form.Control>
              {formik.errors.EntryDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.EntryDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">Status</label>
              <Form.Control
                type="text"
                name="Status"
                className="form-control"
                value={formik.values.Status}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.Status && !!formik.errors.Status}
                isValid={!!formik.touched.Status && !formik.errors.Status}
              ></Form.Control>
              {formik.errors.Status && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.Status}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">CustomerName</label>
              <Form.Control
                type="text"
                name="CustomerName"
                className="form-control"
                value={formik.values.CustomerName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.CustomerName && !!formik.errors.CustomerName
                }
                isValid={
                  !!formik.touched.CustomerName && !formik.errors.CustomerName
                }
              ></Form.Control>
              {formik.errors.CustomerName && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.CustomerName}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">LeadDate</label>
              <Form.Control
                type="date"
                name="LeadDate"
                className="form-control"
                value={formik.values.LeadDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.LeadDate && !!formik.errors.LeadDate
                }
                isValid={!!formik.touched.LeadDate && !formik.errors.LeadDate}
              ></Form.Control>
              {formik.errors.LeadDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.LeadDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">CancelledDate</label>
              <Form.Control
                type="date"
                name="CancelledDate"
                className="form-control"
                value={formik.values.CancelledDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.CancelledDate &&
                  !!formik.errors.CancelledDate
                }
                isValid={
                  !!formik.touched.CancelledDate && !formik.errors.CancelledDate
                }
              ></Form.Control>
              {formik.errors.CancelledDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.CancelledDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">EnqDate</label>
              <Form.Control
                type="date"
                name="EnqDate"
                className="form-control"
                value={formik.values.EnqDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.EnqDate && !!formik.errors.EnqDate}
                isValid={!!formik.touched.EnqDate && !formik.errors.EnqDate}
              ></Form.Control>
              {formik.errors.EnqDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.EnqDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">POL</label>
              <Form.Control
                type="text"
                name="POL"
                className="form-control"
                value={formik.values.POL}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.POL && !!formik.errors.POL}
                isValid={!!formik.touched.POL && !formik.errors.POL}
              ></Form.Control>
              {formik.errors.POL && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.POL}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">POD</label>
              <Form.Control
                type="text"
                name="POD"
                className="form-control"
                value={formik.values.POD}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.POD && !!formik.errors.POD}
                isValid={!!formik.touched.POD && !formik.errors.POD}
              ></Form.Control>
              {formik.errors.POD && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.POD}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">Cntr20</label>
              <Form.Control
                type="text"
                name="Cntr20"
                className="form-control"
                value={formik.values.Cntr20}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.Cntr20 && !!formik.errors.Cntr20}
                isValid={!!formik.touched.Cntr20 && !formik.errors.Cntr20}
              ></Form.Control>
              {formik.errors.Cntr20 && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.Cntr20}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">Cntr40_40Hc</label>
              <Form.Control
                type="text"
                name="Cntr40_40Hc"
                className="form-control"
                value={formik.values.Cntr40_40Hc}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.Cntr40_40Hc && !!formik.errors.Cntr40_40Hc
                }
                isValid={
                  !!formik.touched.Cntr40_40Hc && !formik.errors.Cntr40_40Hc
                }
              ></Form.Control>
              {formik.errors.Cntr40_40Hc && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.Cntr40_40Hc}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">AIR_LCR</label>
              <Form.Control
                type="text"
                name="AIR_LCR"
                className="form-control"
                value={formik.values.AIR_LCR}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.AIR_LCR && !!formik.errors.AIR_LCR}
                isValid={!!formik.touched.AIR_LCR && !formik.errors.AIR_LCR}
              ></Form.Control>
              {formik.errors.AIR_LCR && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.AIR_LCR}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">PeriodOfShpmt</label>
              <Form.Control
                type="text"
                name="PeriodOfShpmt"
                className="form-control"
                value={formik.values.PeriodOfShpmt}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.PeriodOfShpmt &&
                  !!formik.errors.PeriodOfShpmt
                }
                isValid={
                  !!formik.touched.PeriodOfShpmt && !formik.errors.PeriodOfShpmt
                }
              ></Form.Control>
              {formik.errors.PeriodOfShpmt && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.PeriodOfShpmt}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">Commodity</label>
              <Form.Control
                type="text"
                name="Commodity"
                className="form-control"
                value={formik.values.Commodity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.Commodity && !!formik.errors.Commodity
                }
                isValid={!!formik.touched.Commodity && !formik.errors.Commodity}
              ></Form.Control>
              {formik.errors.Commodity && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.Commodity}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">RateReqDate</label>
              <Form.Control
                type="date"
                name="RateReqDate"
                className="form-control"
                value={formik.values.RateReqDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.RateReqDate && !!formik.errors.RateReqDate
                }
                isValid={
                  !!formik.touched.RateReqDate && !formik.errors.RateReqDate
                }
              ></Form.Control>
              {formik.errors.RateReqDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.RateReqDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">RateRcvdDate</label>
              <Form.Control
                type="date"
                name="RateRcvdDate"
                className="form-control"
                value={formik.values.RateRcvdDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.RateRcvdDate && !!formik.errors.RateRcvdDate
                }
                isValid={
                  !!formik.touched.RateRcvdDate && !formik.errors.RateRcvdDate
                }
              ></Form.Control>
              {formik.errors.RateRcvdDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.RateRcvdDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">BuyRate20</label>
              <Form.Control
                type="number"
                name="BuyRate20"
                className="form-control"
                value={formik.values.BuyRate20}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.BuyRate20 && !!formik.errors.BuyRate20
                }
                isValid={!!formik.touched.BuyRate20 && !formik.errors.BuyRate20}
              ></Form.Control>
              {formik.errors.BuyRate20 && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.BuyRate20}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">BuyRate40</label>
              <Form.Control
                type="number"
                name="BuyRate40"
                className="form-control"
                value={formik.values.BuyRate40}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.BuyRate40 && !!formik.errors.BuyRate40
                }
                isValid={!!formik.touched.BuyRate40 && !formik.errors.BuyRate40}
              ></Form.Control>
              {formik.errors.BuyRate40 && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.BuyRate40}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">SellRate20</label>
              <Form.Control
                type="number"
                name="SellRate20"
                className="form-control"
                value={formik.values.SellRate20}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.SellRate20 && !!formik.errors.SellRate20
                }
                isValid={
                  !!formik.touched.SellRate20 && !formik.errors.SellRate20
                }
              ></Form.Control>
              {formik.errors.SellRate20 && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.SellRate20}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">SellRate40</label>
              <Form.Control
                type="number"
                name="SellRate40"
                className="form-control"
                value={formik.values.SellRate40}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.SellRate40 && !!formik.errors.SellRate40
                }
                isValid={
                  !!formik.touched.SellRate40 && !formik.errors.SellRate40
                }
              ></Form.Control>
              {formik.errors.SellRate40 && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.SellRate40}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">Profit</label>
              <Form.Control
                type="number"
                name="Profit"
                className="form-control"
                value={formik.values.Profit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.Profit && !!formik.errors.Profit}
                isValid={!!formik.touched.Profit && !formik.errors.Profit}
              ></Form.Control>
              {formik.errors.Profit && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.Profit}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">QtnSentDate</label>
              <Form.Control
                type="date"
                name="QtnSentDate"
                className="form-control"
                value={formik.values.QtnSentDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.QtnSentDate && !!formik.errors.QtnSentDate
                }
                isValid={
                  !!formik.touched.QtnSentDate && !formik.errors.QtnSentDate
                }
              ></Form.Control>
              {formik.errors.QtnSentDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.QtnSentDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">CnfmRcvdDate</label>
              <Form.Control
                type="date"
                name="CnfmRcvdDate"
                className="form-control"
                value={formik.values.CnfmRcvdDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.CnfmRcvdDate && !!formik.errors.CnfmRcvdDate
                }
                isValid={
                  !!formik.touched.CnfmRcvdDate && !formik.errors.CnfmRcvdDate
                }
              ></Form.Control>
              {formik.errors.CnfmRcvdDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.CnfmRcvdDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">jobdate</label>
              <Form.Control
                type="date"
                name="jobdate"
                className="form-control"
                value={formik.values.jobdate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.jobdate && !!formik.errors.jobdate}
                isValid={!!formik.touched.jobdate && !formik.errors.jobdate}
              ></Form.Control>
              {formik.errors.jobdate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.jobdate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">JobNo</label>
              <Form.Control
                type="text"
                name="JobNo"
                className="form-control"
                value={formik.values.JobNo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.JobNo && !!formik.errors.JobNo}
                isValid={!!formik.touched.JobNo && !formik.errors.JobNo}
              ></Form.Control>
              {formik.errors.JobNo && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.JobNo}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">
                BkgSentToLineorAgtDate
              </label>
              <Form.Control
                type="date"
                name="BkgSentToLineorAgtDate"
                className="form-control"
                value={formik.values.BkgSentToLineorAgtDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.BkgSentToLineorAgtDate &&
                  !!formik.errors.BkgSentToLineorAgtDate
                }
                isValid={
                  !!formik.touched.BkgSentToLineorAgtDate &&
                  !formik.errors.BkgSentToLineorAgtDate
                }
              ></Form.Control>
              {formik.errors.BkgSentToLineorAgtDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.BkgSentToLineorAgtDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">CroReceivedDate</label>
              <Form.Control
                type="date"
                name="CroReceivedDate"
                className="form-control"
                value={formik.values.CroReceivedDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.CroReceivedDate &&
                  !!formik.errors.CroReceivedDate
                }
                isValid={
                  !!formik.touched.CroReceivedDate &&
                  !formik.errors.CroReceivedDate
                }
              ></Form.Control>
              {formik.errors.CroReceivedDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.CroReceivedDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">CroSentToTransDate</label>
              <Form.Control
                type="date"
                name="CroSentToTransDate"
                className="form-control"
                value={formik.values.CroSentToTransDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.CroSentToTransDate &&
                  !!formik.errors.CroSentToTransDate
                }
                isValid={
                  !!formik.touched.CroSentToTransDate &&
                  !formik.errors.CroSentToTransDate
                }
              ></Form.Control>
              {formik.errors.CroSentToTransDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.CroSentToTransDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">CroNo</label>
              <Form.Control
                type="text"
                name="CroNo"
                className="form-control"
                value={formik.values.CroNo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.CroNo && !!formik.errors.CroNo}
                isValid={!!formik.touched.CroNo && !formik.errors.CroNo}
              ></Form.Control>
              {formik.errors.CroNo && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.CroNo}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">ContrNo</label>
              <Form.Control
                type="text"
                name="ContrNo"
                className="form-control"
                value={formik.values.ContrNo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.ContrNo && !!formik.errors.ContrNo}
                isValid={!!formik.touched.ContrNo && !formik.errors.ContrNo}
              ></Form.Control>
              {formik.errors.ContrNo && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.ContrNo}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">ContrNoRecDate</label>
              <Form.Control
                type="date"
                name="ContrNoRecDate"
                className="form-control"
                value={formik.values.ContrNoRecDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.ContrNoRecDate &&
                  !!formik.errors.ContrNoRecDate
                }
                isValid={
                  !!formik.touched.ContrNoRecDate &&
                  !formik.errors.ContrNoRecDate
                }
              ></Form.Control>
              {formik.errors.ContrNoRecDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.ContrNoRecDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">WBillNo</label>
              <Form.Control
                type="text"
                name="WBillNo"
                className="form-control"
                value={formik.values.WBillNo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.WBillNo && !!formik.errors.WBillNo}
                isValid={!!formik.touched.WBillNo && !formik.errors.WBillNo}
              ></Form.Control>
              {formik.errors.WBillNo && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.WBillNo}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">WBillSentDate</label>
              <Form.Control
                type="date"
                name="WBillSentDate"
                className="form-control"
                value={formik.values.WBillSentDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.WBillSentDate &&
                  !!formik.errors.WBillSentDate
                }
                isValid={
                  !!formik.touched.WBillSentDate && !formik.errors.WBillSentDate
                }
              ></Form.Control>
              {formik.errors.WBillSentDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.WBillSentDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">SealNo</label>
              <Form.Control
                type="text"
                name="SealNo"
                className="form-control"
                value={formik.values.SealNo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.SealNo && !!formik.errors.SealNo}
                isValid={!!formik.touched.SealNo && !formik.errors.SealNo}
              ></Form.Control>
              {formik.errors.SealNo && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.SealNo}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">SealNoRecDate</label>
              <Form.Control
                type="date"
                name="SealNoRecDate"
                className="form-control"
                value={formik.values.SealNoRecDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.SealNoRecDate &&
                  !!formik.errors.SealNoRecDate
                }
                isValid={
                  !!formik.touched.SealNoRecDate && !formik.errors.SealNoRecDate
                }
              ></Form.Control>
              {formik.errors.SealNoRecDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.SealNoRecDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">FullMovedToTerminal</label>
              <Form.Control
                type="text"
                name="FullMovedToTerminal"
                className="form-control"
                value={formik.values.FullMovedToTerminal}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.FullMovedToTerminal &&
                  !!formik.errors.FullMovedToTerminal
                }
                isValid={
                  !!formik.touched.FullMovedToTerminal &&
                  !formik.errors.FullMovedToTerminal
                }
              ></Form.Control>
              {formik.errors.FullMovedToTerminal && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.FullMovedToTerminal}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">ShippingInstRecDate</label>
              <Form.Control
                type="date"
                name="ShippingInstRecDate"
                className="form-control"
                value={formik.values.ShippingInstRecDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.ShippingInstRecDate &&
                  !!formik.errors.ShippingInstRecDate
                }
                isValid={
                  !!formik.touched.ShippingInstRecDate &&
                  !formik.errors.ShippingInstRecDate
                }
              ></Form.Control>
              {formik.errors.ShippingInstRecDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.ShippingInstRecDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">
                ShipInstSentDateToSl
              </label>
              <Form.Control
                type="date"
                name="ShipInstSentDateToSl"
                className="form-control"
                value={formik.values.ShipInstSentDateToSl}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.ShipInstSentDateToSl &&
                  !!formik.errors.ShipInstSentDateToSl
                }
                isValid={
                  !!formik.touched.ShipInstSentDateToSl &&
                  !formik.errors.ShipInstSentDateToSl
                }
              ></Form.Control>
              {formik.errors.ShipInstSentDateToSl && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.ShipInstSentDateToSl}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">
                ManifestRecDateFromSl
              </label>
              <Form.Control
                type="date"
                name="ManifestRecDateFromSl"
                className="form-control"
                value={formik.values.ManifestRecDateFromSl}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.ManifestRecDateFromSl &&
                  !!formik.errors.ManifestRecDateFromSl
                }
                isValid={
                  !!formik.touched.ManifestRecDateFromSl &&
                  !formik.errors.ManifestRecDateFromSl
                }
              ></Form.Control>
              {formik.errors.ManifestRecDateFromSl && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.ManifestRecDateFromSl}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">ShiperClDocDate</label>
              <Form.Control
                type="date"
                name="ShiperClDocDate"
                className="form-control"
                value={formik.values.ShiperClDocDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.ShiperClDocDate &&
                  !!formik.errors.ShiperClDocDate
                }
                isValid={
                  !!formik.touched.ShiperClDocDate &&
                  !formik.errors.ShiperClDocDate
                }
              ></Form.Control>
              {formik.errors.ShiperClDocDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.ShiperClDocDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">
                BlDraftFromSlRecDate
              </label>
              <Form.Control
                type="date"
                name="BlDraftFromSlRecDate"
                className="form-control"
                value={formik.values.BlDraftFromSlRecDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.BlDraftFromSlRecDate &&
                  !!formik.errors.BlDraftFromSlRecDate
                }
                isValid={
                  !!formik.touched.BlDraftFromSlRecDate &&
                  !formik.errors.BlDraftFromSlRecDate
                }
              ></Form.Control>
              {formik.errors.BlDraftFromSlRecDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.BlDraftFromSlRecDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">
                BlDraftToCustSentDate
              </label>
              <Form.Control
                type="date"
                name="BlDraftToCustSentDate"
                className="form-control"
                value={formik.values.BlDraftToCustSentDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.BlDraftToCustSentDate &&
                  !!formik.errors.BlDraftToCustSentDate
                }
                isValid={
                  !!formik.touched.BlDraftToCustSentDate &&
                  !formik.errors.BlDraftToCustSentDate
                }
              ></Form.Control>
              {formik.errors.BlDraftToCustSentDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.BlDraftToCustSentDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">
                BlDraftConfFromCustRecDate
              </label>
              <Form.Control
                type="date"
                name="BlDraftConfFromCustRecDate"
                className="form-control"
                value={formik.values.BlDraftConfFromCustRecDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.BlDraftConfFromCustRecDate &&
                  !!formik.errors.BlDraftConfFromCustRecDate
                }
                isValid={
                  !!formik.touched.BlDraftConfFromCustRecDate &&
                  !formik.errors.BlDraftConfFromCustRecDate
                }
              ></Form.Control>
              {formik.errors.BlDraftConfFromCustRecDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.BlDraftConfFromCustRecDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">DocSentToBrokerDate</label>
              <Form.Control
                type="date"
                name="DocSentToBrokerDate"
                className="form-control"
                value={formik.values.DocSentToBrokerDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.DocSentToBrokerDate &&
                  !!formik.errors.DocSentToBrokerDate
                }
                isValid={
                  !!formik.touched.DocSentToBrokerDate &&
                  !formik.errors.DocSentToBrokerDate
                }
              ></Form.Control>
              {formik.errors.DocSentToBrokerDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.DocSentToBrokerDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">
                ManifestSentToBrokerDate
              </label>
              <Form.Control
                type="date"
                name="ManifestSentToBrokerDate"
                className="form-control"
                value={formik.values.ManifestSentToBrokerDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.ManifestSentToBrokerDate &&
                  !!formik.errors.ManifestSentToBrokerDate
                }
                isValid={
                  !!formik.touched.ManifestSentToBrokerDate &&
                  !formik.errors.ManifestSentToBrokerDate
                }
              ></Form.Control>
              {formik.errors.ManifestSentToBrokerDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.ManifestSentToBrokerDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">GatePassRecDate</label>
              <Form.Control
                type="date"
                name="GatePassRecDate"
                className="form-control"
                value={formik.values.GatePassRecDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.GatePassRecDate &&
                  !!formik.errors.GatePassRecDate
                }
                isValid={
                  !!formik.touched.GatePassRecDate &&
                  !formik.errors.GatePassRecDate
                }
              ></Form.Control>
              {formik.errors.GatePassRecDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.GatePassRecDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">
                GatePassSentToTransDate
              </label>
              <Form.Control
                type="date"
                name="GatePassSentToTransDate"
                className="form-control"
                value={formik.values.GatePassSentToTransDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.GatePassSentToTransDate &&
                  !!formik.errors.GatePassSentToTransDate
                }
                isValid={
                  !!formik.touched.GatePassSentToTransDate &&
                  !formik.errors.GatePassSentToTransDate
                }
              ></Form.Control>
              {formik.errors.GatePassSentToTransDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.GatePassSentToTransDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">
                FinalBayanorOkToLoadRecDate
              </label>
              <Form.Control
                type="date"
                name="FinalBayanorOkToLoadRecDate"
                className="form-control"
                value={formik.values.FinalBayanorOkToLoadRecDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.FinalBayanorOkToLoadRecDate &&
                  !!formik.errors.FinalBayanorOkToLoadRecDate
                }
                isValid={
                  !!formik.touched.FinalBayanorOkToLoadRecDate &&
                  !formik.errors.FinalBayanorOkToLoadRecDate
                }
              ></Form.Control>
              {formik.errors.FinalBayanorOkToLoadRecDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.FinalBayanorOkToLoadRecDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">
                LoadingConfirmationRecDate
              </label>
              <Form.Control
                type="date"
                name="LoadingConfirmationRecDate"
                className="form-control"
                value={formik.values.LoadingConfirmationRecDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.LoadingConfirmationRecDate &&
                  !!formik.errors.LoadingConfirmationRecDate
                }
                isValid={
                  !!formik.touched.LoadingConfirmationRecDate &&
                  !formik.errors.LoadingConfirmationRecDate
                }
              ></Form.Control>
              {formik.errors.LoadingConfirmationRecDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.LoadingConfirmationRecDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">PreAlertSentDate</label>
              <Form.Control
                type="date"
                name="PreAlertSentDate"
                className="form-control"
                value={formik.values.PreAlertSentDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.PreAlertSentDate &&
                  !!formik.errors.PreAlertSentDate
                }
                isValid={
                  !!formik.touched.PreAlertSentDate &&
                  !formik.errors.PreAlertSentDate
                }
              ></Form.Control>
              {formik.errors.PreAlertSentDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.PreAlertSentDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">
                ArrivalNotificationSentDate
              </label>
              <Form.Control
                type="date"
                name="ArrivalNotificationSentDate"
                className="form-control"
                value={formik.values.ArrivalNotificationSentDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.ArrivalNotificationSentDate &&
                  !!formik.errors.ArrivalNotificationSentDate
                }
                isValid={
                  !!formik.touched.ArrivalNotificationSentDate &&
                  !formik.errors.ArrivalNotificationSentDate
                }
              ></Form.Control>
              {formik.errors.ArrivalNotificationSentDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.ArrivalNotificationSentDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">InvoicePreparedDate</label>
              <Form.Control
                type="date"
                name="InvoicePreparedDate"
                className="form-control"
                value={formik.values.InvoicePreparedDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.InvoicePreparedDate &&
                  !!formik.errors.InvoicePreparedDate
                }
                isValid={
                  !!formik.touched.InvoicePreparedDate &&
                  !formik.errors.InvoicePreparedDate
                }
              ></Form.Control>
              {formik.errors.InvoicePreparedDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.InvoicePreparedDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">HBLDate</label>
              <Form.Control
                type="date"
                name="HBLDate"
                className="form-control"
                value={formik.values.HBLDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.HBLDate && !!formik.errors.HBLDate}
                isValid={!!formik.touched.HBLDate && !formik.errors.HBLDate}
              ></Form.Control>
              {formik.errors.HBLDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.HBLDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">MBLDate</label>
              <Form.Control
                type="date"
                name="MBLDate"
                className="form-control"
                value={formik.values.MBLDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.MBLDate && !!formik.errors.MBLDate}
                isValid={!!formik.touched.MBLDate && !formik.errors.MBLDate}
              ></Form.Control>
              {formik.errors.MBLDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.MBLDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">
                DebitorCreditNoteDate
              </label>
              <Form.Control
                type="date"
                name="DebitorCreditNoteDate"
                className="form-control"
                value={formik.values.DebitorCreditNoteDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.DebitorCreditNoteDate &&
                  !!formik.errors.DebitorCreditNoteDate
                }
                isValid={
                  !!formik.touched.DebitorCreditNoteDate &&
                  !formik.errors.DebitorCreditNoteDate
                }
              ></Form.Control>
              {formik.errors.DebitorCreditNoteDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.DebitorCreditNoteDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">ETADate</label>
              <Form.Control
                type="date"
                name="ETADate"
                className="form-control"
                value={formik.values.ETADate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.ETADate && !!formik.errors.ETADate}
                isValid={!!formik.touched.ETADate && !formik.errors.ETADate}
              ></Form.Control>
              {formik.errors.ETADate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.ETADate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">
                TelexReleaseorSeawayBillDate
              </label>
              <Form.Control
                type="date"
                name="TelexReleaseorSeawayBillDate"
                className="form-control"
                value={formik.values.TelexReleaseorSeawayBillDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.TelexReleaseorSeawayBillDate &&
                  !!formik.errors.TelexReleaseorSeawayBillDate
                }
                isValid={
                  !!formik.touched.TelexReleaseorSeawayBillDate &&
                  !formik.errors.TelexReleaseorSeawayBillDate
                }
              ></Form.Control>
              {formik.errors.TelexReleaseorSeawayBillDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.TelexReleaseorSeawayBillDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">
                OblorGuaranteeCollectedDate
              </label>
              <Form.Control
                type="date"
                name="OblorGuaranteeCollectedDate"
                className="form-control"
                value={formik.values.OblorGuaranteeCollectedDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.OblorGuaranteeCollectedDate &&
                  !!formik.errors.OblorGuaranteeCollectedDate
                }
                isValid={
                  !!formik.touched.OblorGuaranteeCollectedDate &&
                  !formik.errors.OblorGuaranteeCollectedDate
                }
              ></Form.Control>
              {formik.errors.OblorGuaranteeCollectedDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.OblorGuaranteeCollectedDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">
                DocumentsReleasedDate
              </label>
              <Form.Control
                type="date"
                name="DocumentsReleasedDate"
                className="form-control"
                value={formik.values.DocumentsReleasedDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.DocumentsReleasedDate &&
                  !!formik.errors.DocumentsReleasedDate
                }
                isValid={
                  !!formik.touched.DocumentsReleasedDate &&
                  !formik.errors.DocumentsReleasedDate
                }
              ></Form.Control>
              {formik.errors.DocumentsReleasedDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.DocumentsReleasedDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">PaymentRecDate</label>
              <Form.Control
                type="date"
                name="PaymentRecDate"
                className="form-control"
                value={formik.values.PaymentRecDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.PaymentRecDate &&
                  !!formik.errors.PaymentRecDate
                }
                isValid={
                  !!formik.touched.PaymentRecDate &&
                  !formik.errors.PaymentRecDate
                }
              ></Form.Control>
              {formik.errors.PaymentRecDate && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.PaymentRecDate}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="col-md-4">
              <label className="form -control-label">Remarks</label>
              <Form.Control
                type="text"
                name="Remarks"
                className="form-control"
                value={formik.values.Remarks}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.touched.Remarks && !!formik.errors.Remarks}
                isValid={!!formik.touched.Remarks && !formik.errors.Remarks}
              ></Form.Control>
              {formik.errors.Remarks && (
                <Form.Control.Feedback type="invalid">
                  <>{formik.errors.Remarks}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </div>
          <Form.Group>
            <Button type="submit" className="float-right" variant="primary">
              Save
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
