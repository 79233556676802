import { APIService } from "services";

export const getJob1 = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllJob1(pageNo,pageSize);
    }
    else{
        try {
            res = await searchJob1(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data){
    return res.data;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addJob1 = (data) => {
return APIService.api().post(`/job1`,data)
}
export const updateJob1 = (RecId,data) => {
return APIService.api().patch(`/job1/${RecId}`,data)
}
export const getAllJob1 = (pageNo,pageSize) => {
return APIService.api().get(`/job1/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const getOneJob1 = (RecId) => {
return APIService.api().get(`/job1/${RecId}`)
}
export const searchJob1 = (searchKey,pageNo,pageSize) => {
return APIService.api().get(`/job1/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const deleteJob1 = (RecId) => {
return APIService.api().delete(`/job1/${RecId}`)
}
