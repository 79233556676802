import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setJob1List, setJob1Message } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getReport } from "services/reportService";
import Layout from "template";
import { Constant } from "template/Constant";
// import { ReportForm } from "./form";
// import { Job1Table } from "./table";

export const ReportDashboard: React.FC = () => {
  return (
    <Layout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-6">
          <h1 className="h5 mb-0 text-gray-800">Report Dashboard</h1>
        </div>
        <div className="d-flex flex-column min-vh-100">
          <iframe src="www.google.com"></iframe>
        </div>
      </div>
    </Layout>
  );
};
