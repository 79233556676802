import { APIService } from "services";



export const getReport = async (param1,param2,search) => {
    let res;
    if(search.length===0) {
        res = await getAllReport(param1,param2);
    }
    
    if(res && res.data){
    return res.data;
    }else{
    return { records:[], totalCount:0 }
    }
    
}

export const getAllReport = (param1,param2) => {
    return APIService.api().get(`/report/?param1=${param1}&param2=${param2}`)
    }

export const processReport = (RecId,data) => {
return APIService.api().patch(`/report/${RecId}`,data)
}
