import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./login";
import Register from "./register";
import AuthenticatedRoute from "components/auth";
import { Dashboard } from "components/dashboard";
import { ReportDashboard } from "components/reportdashboard";
import { FileUpload } from "components/upload";
import { NotFound } from "./404";
import { Job1 } from "components";
import { Report } from "components";
const Pages: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route
          path="/dashboard"
          element={<AuthenticatedRoute element={<Dashboard />} />}
        ></Route>
        <Route
          path="/upload"
          element={<AuthenticatedRoute element={<FileUpload />} />}
        ></Route>
        <Route
          path="/job1"
          element={<AuthenticatedRoute element={<Job1 />} />}
        ></Route>
        <Route
          path="/report"
          element={<AuthenticatedRoute element={<Report />} />}
        ></Route>
        <Route
          path="/ReportDashboard"
          element={<AuthenticatedRoute element={<ReportDashboard />} />}
        ></Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Pages;
