import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetJob1ToInit, setJob1Message } from "redux/actions";
import { Button, Card, Col, Form, InputGroup } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteJob1 } from "services/job1Service";
type Props = {
  hideShowForm: (action) => void;
  handleRowEdit: (row) => void;
  getData: (page, pageSize, searchKey) => void;
};
export const Job1Table: React.FC<Props> = ({
  hideShowForm,
  handleRowEdit,
  getData,
}) => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [rowData, setRowData] = useState(undefined);
  const rData = useSelector((state: RootState) => state.job1);
  const handleSearch = () => {
    if (search.length > 0) {
      getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
    }
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    await getData(page, newPerPage, "");
  };
  const handlePageChange = (page) => {
    getData(page, rData.pageSize, "");
  };
  const handleRowDeleteClick = (row) => {
    setRowData(row);
    setShowDelete(true);
  };
  useEffect(() => {
    if (rData && rData.list && rData.list.length === 0) {
      dispatch(resetJob1ToInit());
      getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
    }
  }, [rData.list.length]);
  const handleReset = () => {
    setSearch("");
    dispatch(resetJob1ToInit());
    getData(Constant.defaultPageNumber, rData.pageSize, "");
  };
  const handleServerDelete = async () => {
    if (rowData) {
      const response = await deleteJob1(rowData.RecId);
      if (response) {
        dispatch(resetJob1ToInit());
        dispatch(setJob1Message("Deleted Successfully"));
        getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
        setShowDelete(false);
      } else {
        dispatch(setJob1Message("Some error occured!"));
      }
    }
  };

  const handleRowSelection = (row) => {
    console.log(row); // Row Selection Functionality can be written here
  };
  const handleAddButtonClick = () => {
    dispatch(setJob1Message(""));
    hideShowForm("add");
  };

  const columns = [
    {
      name: "",
      button: true,
      cell: (row) => (
        <Button
          variant="link"
          className="btn-sm"
          onClick={() => handleRowEdit(row)}
        >
          Edit
        </Button>
      ),
    },
    {
      name: "",
      button: true,
      cell: (row) => (
        <Button
          variant="link"
          className="btn-sm text-danger"
          onClick={() => handleRowDeleteClick(row)}
        >
          Delete
        </Button>
      ),
    },
    { name: "RecId", selector: (row) => row.RecId, sortable: true },
    { name: "EntryDate", selector: (row) => row.EntryDate, sortable: true },
    { name: "Status", selector: (row) => row.Status, sortable: true },
    {
      name: "CustomerName",
      selector: (row) => row.CustomerName,
      sortable: true,
    },
    { name: "LeadDate", selector: (row) => row.LeadDate, sortable: true },
    {
      name: "CancelledDate",
      selector: (row) => row.CancelledDate,
      sortable: true,
    },
    { name: "EnqDate", selector: (row) => row.EnqDate, sortable: true },
    { name: "POL", selector: (row) => row.POL, sortable: true },
    { name: "POD", selector: (row) => row.POD, sortable: true },
    { name: "Cntr20", selector: (row) => row.Cntr20, sortable: true },
    { name: "Cntr40_40Hc", selector: (row) => row.Cntr40_40Hc, sortable: true },
    { name: "AIR_LCR", selector: (row) => row.AIR_LCR, sortable: true },
    {
      name: "PeriodOfShpmt",
      selector: (row) => row.PeriodOfShpmt,
      sortable: true,
    },
    { name: "Commodity", selector: (row) => row.Commodity, sortable: true },
    { name: "RateReqDate", selector: (row) => row.RateReqDate, sortable: true },
    {
      name: "RateRcvdDate",
      selector: (row) => row.RateRcvdDate,
      sortable: true,
    },
    { name: "BuyRate20", selector: (row) => row.BuyRate20, sortable: true },
    { name: "BuyRate40", selector: (row) => row.BuyRate40, sortable: true },
    { name: "SellRate20", selector: (row) => row.SellRate20, sortable: true },
    { name: "SellRate40", selector: (row) => row.SellRate40, sortable: true },
    { name: "Profit", selector: (row) => row.Profit, sortable: true },
    { name: "QtnSentDate", selector: (row) => row.QtnSentDate, sortable: true },
    {
      name: "CnfmRcvdDate",
      selector: (row) => row.CnfmRcvdDate,
      sortable: true,
    },
    { name: "jobdate", selector: (row) => row.jobdate, sortable: true },
    { name: "JobNo", selector: (row) => row.JobNo, sortable: true },
    {
      name: "BkgSentToLineorAgtDate",
      selector: (row) => row.BkgSentToLineorAgtDate,
      sortable: true,
    },
    {
      name: "CroReceivedDate",
      selector: (row) => row.CroReceivedDate,
      sortable: true,
    },
    {
      name: "CroSentToTransDate",
      selector: (row) => row.CroSentToTransDate,
      sortable: true,
    },
    { name: "CroNo", selector: (row) => row.CroNo, sortable: true },
    { name: "ContrNo", selector: (row) => row.ContrNo, sortable: true },
    {
      name: "ContrNoRecDate",
      selector: (row) => row.ContrNoRecDate,
      sortable: true,
    },
    { name: "WBillNo", selector: (row) => row.WBillNo, sortable: true },
    {
      name: "WBillSentDate",
      selector: (row) => row.WBillSentDate,
      sortable: true,
    },
    { name: "SealNo", selector: (row) => row.SealNo, sortable: true },
    {
      name: "SealNoRecDate",
      selector: (row) => row.SealNoRecDate,
      sortable: true,
    },
    {
      name: "FullMovedToTerminal",
      selector: (row) => row.FullMovedToTerminal,
      sortable: true,
    },
    {
      name: "ShippingInstRecDate",
      selector: (row) => row.ShippingInstRecDate,
      sortable: true,
    },
    {
      name: "ShipInstSentDateToSl",
      selector: (row) => row.ShipInstSentDateToSl,
      sortable: true,
    },
    {
      name: "ManifestRecDateFromSl",
      selector: (row) => row.ManifestRecDateFromSl,
      sortable: true,
    },
    {
      name: "ShiperClDocDate",
      selector: (row) => row.ShiperClDocDate,
      sortable: true,
    },
    {
      name: "BlDraftFromSlRecDate",
      selector: (row) => row.BlDraftFromSlRecDate,
      sortable: true,
    },
    {
      name: "BlDraftToCustSentDate",
      selector: (row) => row.BlDraftToCustSentDate,
      sortable: true,
    },
    {
      name: "BlDraftConfFromCustRecDate",
      selector: (row) => row.BlDraftConfFromCustRecDate,
      sortable: true,
    },
    {
      name: "DocSentToBrokerDate",
      selector: (row) => row.DocSentToBrokerDate,
      sortable: true,
    },
    {
      name: "ManifestSentToBrokerDate",
      selector: (row) => row.ManifestSentToBrokerDate,
      sortable: true,
    },
    {
      name: "GatePassRecDate",
      selector: (row) => row.GatePassRecDate,
      sortable: true,
    },
    {
      name: "GatePassSentToTransDate",
      selector: (row) => row.GatePassSentToTransDate,
      sortable: true,
    },
    {
      name: "FinalBayanorOkToLoadRecDate",
      selector: (row) => row.FinalBayanorOkToLoadRecDate,
      sortable: true,
    },
    {
      name: "LoadingConfirmationRecDate",
      selector: (row) => row.LoadingConfirmationRecDate,
      sortable: true,
    },
    {
      name: "PreAlertSentDate",
      selector: (row) => row.PreAlertSentDate,
      sortable: true,
    },
    {
      name: "ArrivalNotificationSentDate",
      selector: (row) => row.ArrivalNotificationSentDate,
      sortable: true,
    },
    {
      name: "InvoicePreparedDate",
      selector: (row) => row.InvoicePreparedDate,
      sortable: true,
    },
    { name: "HBLDate", selector: (row) => row.HBLDate, sortable: true },
    { name: "MBLDate", selector: (row) => row.MBLDate, sortable: true },
    {
      name: "DebitorCreditNoteDate",
      selector: (row) => row.DebitorCreditNoteDate,
      sortable: true,
    },
    { name: "ETADate", selector: (row) => row.ETADate, sortable: true },
    {
      name: "TelexReleaseorSeawayBillDate",
      selector: (row) => row.TelexReleaseorSeawayBillDate,
      sortable: true,
    },
    {
      name: "OblorGuaranteeCollectedDate",
      selector: (row) => row.OblorGuaranteeCollectedDate,
      sortable: true,
    },
    {
      name: "DocumentsReleasedDate",
      selector: (row) => row.DocumentsReleasedDate,
      sortable: true,
    },
    {
      name: "PaymentRecDate",
      selector: (row) => row.PaymentRecDate,
      sortable: true,
    },
    { name: "Remarks", selector: (row) => row.Remarks, sortable: true },
  ];
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary">
          Job List ({rData.totalCount})
          <Button
            variant="light"
            className="btn-circle btn-sm ml-2"
            onClick={handleReset}
          >
            <i className="fa fa-refresh"></i>
          </Button>
          <Button
            className="btn-icon-split float-right"
            onClick={handleAddButtonClick}
          >
            <span className="icon text-white-50">
              <i className="fas fa-add"></i>
            </span>
            <span className="text">Add New</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Col className="ml-auto" md={3} xs={12} xl={3}>
          <InputGroup>
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-search"
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              disabled={search.length <= 2}
              variant="dark"
              className="rounded-0 rounded-right"
              id="button-search"
              onClick={handleSearch}
            >
              Search
            </Button>
          </InputGroup>
        </Col>
        <div className="table-responsive">
          <DataTable
            selectableRows={true}
            onSelectedRowsChange={handleRowSelection}
            paginationPerPage={Constant.defaultPageNumber}
            paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
            columns={columns}
            data={rData.list}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationTotalRows={rData.totalCount}
            className="table table-bordered"
            pagination
            paginationServer
            onChangePage={handlePageChange}
          ></DataTable>
        </div>
      </Card.Body>
      <ConfirmationModal
        buttonNegative="Cancel"
        buttonPositive="Delete"
        title="Delete Confirmation"
        show={showDelete}
        body={"Are you sure?"}
        onNegative={() => setShowDelete(false)}
        onPositive={handleServerDelete}
      />
    </Card>
  );
};
