import { Field, useFormik } from "formik";
import React from "react";
import DatePicker from "react-datepicker";
import { Col, Row } from "react-bootstrap";

import { Button, Card, Form } from "react-bootstrap";
import { setJob1Message } from "redux/actions";

import { useAppDispatch } from "redux/store";
import { processReport } from "services/reportService";
import { Constant } from "template/Constant";
import * as yup from "yup";
import moment from "moment";
import { Link } from "react-router-dom";
const style1 = {
  border: "none",
  margin: 0,
  width: "100%",
  height: "1000px",
  "z-index": "9999",
};
let iframeKey = 1;
// function handleiFrameReload() {
//   iframeKey = Math.random();
// }

type Props = {
  row?: any;
  hideShowForm: (actionName) => void;
  getData: (page, pageSize, searchKey) => void;
  action?: string;
};
export const ReportForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();

  const iValue = {
    DateFrom: null,
    DateTo: null,
  };
  const initialValue = action === "edit" ? row : iValue;

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      console.log(iframeKey);

      const response = await processReport(0, values);
      if (response && (response.status === 200 || response.status === 201)) {
        // dispatch(setJob1Message("Report Freshed"));
        getData(values["DateFrom"], values["DateTo"], "");
        setTimeout(() => {
          // handleiFrameReload();
          iframeKey = Math.random();
          console.log("Iframe Refreshed", iframeKey);
        }, 3000);
        hideShowForm("");
      } else {
        dispatch(setJob1Message("Some error occured!"));
      }
    },
    validationSchema: yup.object({
      DateFrom: yup.date().nullable(),
      DateTo: yup.string().nullable(),
    }),
  });
  return (
    <div>
      <Card className="shadow mb-4">
        <Card.Header className="py-3">
          <h6 className="m-0 font-weight-bold text-primary text-capitalize">
            Enter the Report Period
          </h6>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <Form.Group className="col-md-4">
                <label className="form -control-label">Date From</label>
                <Form.Control
                  type="date"
                  name="DateFrom"
                  className="form-control"
                  // value={moment(formik.values.EntryDate, "dd-MM-YYYY").toString()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="col-md-4">
                <label className="form -control-label">Date To</label>
                <Form.Control
                  type="date"
                  name="DateTo"
                  className="form-control"
                  // value={moment(formik.values.EntryDate, "dd-MM-YYYY").toString()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                ></Form.Control>
              </Form.Group>
            </div>
            <Form.Group>
              <Button type="submit" className="float-right" variant="primary">
                OK
              </Button>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
      <div>
        {/* <iframe
          id="myIframe"
          style={style1}
          key={iframeKey}
          //src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSH31ypIyDAvw5NBXLLidzeFOf5gmJVKqCb4-wWJyz4ltBHIh0Eaq4EkQHpNrpd7GcPron2Q054HVIi/pubhtml?widget=false&amp;headers=false"
          src="https://docs.google.com/spreadsheets/u/2/d/1QOpaJuEMCNqAdvFeol6s6SZIdURn43P4AugwQxncmtY/htmlembed/sheet?gid=512622066&range=D4"
        ></iframe>
         */}
        <object
          style={style1}
          data="https://docs.google.com/spreadsheets/d/1QOpaJuEMCNqAdvFeol6s6SZIdURn43P4AugwQxncmtY/edit#gid=512622066"
          width="800"
          height="800"
          type="text/html"
        ></object>
      </div>
    </div>
  );
};
function useState(arg0: number): [any, any] {
  throw new Error("Function not implemented.");
}
