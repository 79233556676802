import { combineReducers } from "redux";

import template from "redux/slices/template";
import authToken from "redux/slices/auth";

import job1 from "redux/slices/job1";


const rootReducer = combineReducers({ template,authToken,job1 });

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

