import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setJob1List, setJob1Message } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getJob1 } from "services/job1Service";
import Layout from "template";
import { Constant } from "template/Constant";
import { Job1Form } from "./form";
import { Job1Table } from "./table";

export const Job1: React.FC = () => {
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.job1);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState("");
  const getData = (page, pageSize, searchKey) => {
    getJob1(page, pageSize, searchKey).then((response) => {
      console.log(response);

      if (response && response.records) {
        dispatch(
          setJob1List({
            pageNo: page,
            pageSize: pageSize,
            list: response.records,
            totalCount: response.totalCount,
            searchKey: searchKey,
          })
        );
      } else {
        dispatch(setJob1Message("No Record Found"));
      }
    });
  };

  const handleRowEdit = (rowData) => {
    setRow(rowData);
    dispatch(setJob1Message(""));
    setAction("edit");
  };
  return (
    <Layout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Job Details</h1>
        </div>
        <div className="d-flex flex-column min-vh-100">
          {rData.message ? (
            <Alert
              variant={Constant.defaultAlertVarient}
              className="alert-dismissible fade"
            >
              {rData.message}
              <Button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() => dispatch(setJob1Message(""))}
              >
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert>
          ) : null}
          {action ? (
            <Job1Form
              hideShowForm={setAction}
              action={action}
              row={row}
              getData={getData}
            />
          ) : (
            <Job1Table
              handleRowEdit={handleRowEdit}
              hideShowForm={setAction}
              getData={getData}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};
